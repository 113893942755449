<template>
	<div>
		<h4>You do not have permission to access this resource!</h4>
	</div>
</template>

<script>
export default {
	name: "NoAccess"
};
</script>

<style scoped>
h4 {
	text-align: center;
}
</style>
